.featured-items__wrap {
    overflow: auto;
    width: 340;
  }

  .featured-items__title {
    margin-bottom: 20px;
    text-transform: uppercase;
    position: relative;
  
    &:not(:first-child) {
      margin-top: 40px;
    }
  
    .subhead {
      text-transform: none;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.7;
      margin-top: 3px;
    }
  
    * {
      margin-bottom: 0;
    }
  
    h5 {
      font-size: 13px;
    }
  }
  
  .featured-items {
    width: 320;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .featured-item {
    margin-bottom: 6px;
    margin-left: 1px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1px 1px;
  
    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  
  
  .featured-item__link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1px 1px;
  
    &:hover {
      text-decoration: none;
  
      .featured-item__title {
        color: $color-accent;
      }
    }
  }
  
  .featured-item__img-wrap {
    width: 55px;
    height: 55px;
    margin-bottom: 3px;
    margin-right: 3px;
    overflow: hidden;
  }
  
  .featured-item__img {
    height: 55px;
    width: 55px;
  }
  
  .featured-item__info {
    position: relative;
    display: inline;
  }
  
  .featured-item__title {
    font-weight: 500;
    font-size: 12px;
    transition: all 0.3s;
  }
  
  .featured-item__description {
    margin: 0;
    color: $color-additional;
    line-height: 5px;
  }
  
  .featured-item__price {
    position: absolute;
    top: 0;
    right: -90px;
    line-height: 36px;
  }
  
  .featured-item__old-price {
    position: absolute;
    right: -90px;
    top: 36px;
    line-height: 28px;
    color: $color-additional;
    margin: 0;
    text-decoration: line-through;
  }
  
  .featured-item__color {
    height: 10px;
    width: 10px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .featured-item__label {
    position: absolute;
    top: -2px;
    right: 20px;
    width: 50px;
  }

}