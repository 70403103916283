.product-gallery {
}

.product-gallery__current-img {
  max-width: 440px;
  max-height: 440px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
  }

  @media (min-width: breakpoint(xs-start)) and (max-width: breakpoint(xs-end)) {
    max-width: 240px;
    max-height: 220px;
  }

  img {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

.product_gallery__gallery {
  display: flex;
}

.product-gallery__img-preview {
  width: 80px;
  max-height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
  background: transparent;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {

  .product-gallery {
    margin: auto;
  }
}