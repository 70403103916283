.inspiration-card {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  
    .inspiration-card__btn-toolbar {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  
  .inspiration-card__info {
    width: calc(100% - 540px);
    padding-left: 42px;
  
    @media screen and (max-width: 1199px) {
      width: 100%;
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
  
  .inspiration-card__wish-btn {
    display: block;
    padding: 10px 0;
    font-size: 14px;
    color: $color-blue;
    height: 42px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
  
    svg {
      height: 14px;
      fill: $color-blue;
      transition: all 0.3s;
    }
  
    &:hover {
      color: $color-blue-hover;
  
      svg {
        fill: $color-blue-hover;
      }
    }
  }
  
  .inspiration-card__title {
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .inspiration-card__rate {
    display: flex;
    margin-bottom: 30px;
  
    svg {
      fill: $color-yellow;
      height: 14px;
      width: 14px;
    }
  }
  
  .inspiration-card__link {
    font-size: 12px;
    color: $color-blue;
    line-height: 16px;
    display: block;
    margin-left: 5px;
  
    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
  
  .inspiration-card__price {
    margin-bottom: 25px;
  }
  
  .inspiration-card__old-price {
    font-size: 24px;
    color: $color-additional;
    text-decoration: line-through;
  }
  
  .inspiration-card__form {
    margin-top: 20px;
  }
  
  .inspiration-card__form-label {
    font-weight: 500;
  }
 
  .inspiration-gallery {
}

.inspiration-gallery__current-img {
  max-width: 540px;
  max-height: 356.4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 8px 8px 0 0;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
  }
 
  @media screen and (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    max-width: 991px;
    max-height: 660px;

    img {
      width: 66.6%;
      height: auto;
      overflow: hidden;
    }
  }

  //assumes image size height/width ratio is 66.6%
  @media screen and (min-width: breakpoint(xs-start)) and (max-width: breakpoint(xs-end)) {
    max-width: 375px;
    max-height: 250px;

    img {
      width: 66.6%;
      height: auto;
      overflow: hidden;
    }
  }

  img {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}

.inspiration_gallery__gallery {
  display: flex;
}

.inspiration-gallery__img-preview {
  width: 80px;
  max-height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
  background: transparent;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {

  .inspiration-gallery {
    margin: auto;
  }
}