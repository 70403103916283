.newarrival-items__wrap {
    overflow: hidden;
    width: 100%;
  }
  
  .newarrival-items {
    width: 400px;
    height: auto;
    display: block;
  }
  
  .newarrival-item {
    width: 220px;
    height: 220px;
    margin-right: 30px;
    position: relative;
  
    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  
    @media screen and (max-width: 1500px) {
      width: calc(33.3333% - 30px);
    }
  
    @media screen and (max-width: 1200px) {
      width: calc(50% - 30px);
    }
  
    @media screen and (max-width: 992px) {
      width: calc(100% - 30px);
    }
  }
  
  .newarrival-item__link {
    padding: 40px 30px;
    display: block;
  
    &:hover {
      text-decoration: none;
  
      .newarrival-item__title {
        color: $color-accent;
      }
    }
  }
  
  .newarrival-item__img-wrap {
    width: 200px;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  .newarrival-item__img {
    height: auto;
    width: 100%;
  }
  
  .newarrival-item__info {
    position: relative;
    width: calc(100% - 90px);
  }
  
  .newarrival-item__title {
    color: #646777;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    transition: all 0.3s;
  }
  
  .newarrival-item__description {
    margin: 0;
    color: $color-additional;
    line-height: 17px;
  }
  
  .newarrival-item__price {
    position: absolute;
    top: 0;
    right: -90px;
    line-height: 36px;
  }
  
  .newarrival-item__old-price {
    position: absolute;
    right: -90px;
    top: 36px;
    line-height: 28px;
    color: $color-additional;
    margin: 0;
    text-decoration: line-through;
  }
  
  .newarrival-item__color {
    height: 10px;
    width: 10px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  .newarrival-item__label {
    position: absolute;
    top: -2px;
    right: 20px;
    width: 50px;
  }