.inspiration-image {
    position: relative;
    display: inline-block;
  
    .inspiration-image__menu {
      position: absolute;
      z-index: 1;
      margin: 0 auto;
      left: 3%;
      right: 0;
      top: 5%;
      width: 90%;
  }

  .inspiration-image__img-wrap {
      width: 361px;
      height: 238px;
      border-radius: 8px;
      overflow: hidden;

  }

  .inspiration-image__img {
      width: 361px;
      height: 238px;
      
  }

  .inspiration-image__title {
    position: relative;
    z-index: 999;
    margin: 0 auto;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
  }

  .inspiration-image__caption {
    position: absolute;
    bottom: 30px;
    left: 75px;
  }

  .inspiration-image__shop-btn {
      background-color: white;
  }

.inspiration__caption-description {
    font-size: 12px;
    font-weight: 500;
    color: white;
    margin: 0;
    line-height: 12px;
  }

}

.block-inspirations-slideshow__body {
   /* the slides */
    .slick-slide {
        margin: 0px 2px 0px 0px;
    }

    /* the parent */
    .slick-list {
        margin: 0px -2px 0px 0px;
        height: 240px;
    }
}

/*
// Inspiration Image Featured Items
*/
@import '../variables';
@import '../mixins/hacks';
@import '../mixins/direction';


.featured-items {
    width: 250px;
    font-weight: $font-weight-normal;
    color: #3d464d; 
    background: white; 
    box-shadow: 0 1px 15px rgba(#000, .25);
    border-radius: 8px;

    // without it IE renders a blurred menu
    @include only-ie() {
        box-shadow: 0 0 0 1px rgba(#000, .15);
    }
  .featured-items__title {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: black;
    border-bottom: solid 1px;
  }

    .featured-items__empty {
        padding: 42px 30px;
        text-align: center;
    }
    .featured-items__products-list {
        padding: 10px 10px;
    }
    .featured-items__product {
        display: flex;

        & + & {
            margin-top: 10px;
        }
    }
    .featured-items__product-image {
        width: 45px;
        height: 'auto';
        flex-shrink: 0;

        img {
            max-width: 100%;
        }
    }
    .featured-items__product-name {
        margin-top: -2px;
        line-height: 14px;
        font-size: 12px;

        a {
            color: inherit;
            transition: .15s;
        }
        a:hover {
            color: $link-hover-color;
        }
    }
    .featured-items__product-info {
        margin: 0;
        flex-grow: 1;

        @include direction {
            #{$padding-inline-end}: 10px;
            #{$padding-inline-start}: 16px;
        }
    }
    .featured-items__product-options {
        list-style: none;
        margin: 3px 0 0;
        padding: 0;
        font-size: 13px;
        line-height: 16px;
        color: #6c757d;
    }
    .featured-items__product-meta {
        margin-top: 5px;
        font-size: 13px;
    }
    .featured-items__product-price {
        font-size: 12px;
        font-weight: 350;
    }
}
