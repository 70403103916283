.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  background: white;
  padding: 20px 40px 30px 25px;
  position: relative;
  margin: 10px 25px;

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--success {
    background: $color-accent;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message, .notification__title {
      color: white;
    }
  }
}

.notification__message {
  margin-top: 0;
  font-size: 12px;
  color: $color-additional;
}

.notification__title {
  margin-bottom: 8px;
  color: #646777;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  align-items: center;
  top: 10px;
  left: 0;
  
  img {
    width: 85px;
    height: 85px;
  }

  & ~ * {
    padding-left: 106px;
  }
}

.sidebar.sidebar--collapse + .container__wrap {

  .notifications-tl, .notifications-bl {
    left: 0 !important;
  }
}

// rc-notification
.rc-notification {
  position: fixed;
  z-index: 100;
}

.rc-notification-notice {
  display: block;
  width: auto;
  line-height: 1.5;
  vertical-align: middle;
  position: relative;
}

.rc-notification-notice-close {
  position: absolute;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  line-height: 1;
  opacity: .2;
  text-decoration: none;
}

.rc-notification-notice-close-x:after {
  content: '×';
}

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running;

  &.right-up {
    animation-name: rcNotificationRightFadeIn;
  }

  &.left-up {
    animation-name: rcNotificationLeftFadeIn;
  }
}

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.rc-notification {
  left: auto !important;
  top: 60px !important;
  height: 0;

  & > span {
    height: 0;
    display: block;
  }
}

.rc-notification-notice-close {
  right: 45px;
  top: 15px;
  font-size: 26px;
  line-height: 1;
  font-weight: 300;
  color: #646777;
  text-shadow: none;
  opacity: 0.2;

  &:hover {
    opacity: 0.5;
    color: #646777;
  }
}